import { useCallback, useEffect, useRef, useState } from "react";
import { ITouchData } from "../types";
import { getIsVisible } from "./getIsVisible";
import { getSwipeParams } from "./getSwipeParams";
import { scrollSlider } from "./scrollSlider";
import { setBoundaryPosition } from "./setBoundaryPosition";
import { setSlidesDataStatus } from "./setSlidesDataStatus";

const touchXData: ITouchData = {
  touchStart: 0,
  timestampStart: 0,
  touchEnd: 0,
  timestampEnd: 0,
  touchCurrent: 0,
};

interface SliderProps {
  fullWidthScroll?: boolean;
  slidesPerScroll?: number;
  autoScrollTimer?: number;
  duration?: number;
  withAutoScroll?: boolean;
  onSwipe?: (value: number) => void;
  onEndPositionLeft?: (value: boolean) => void;
  onEndPositionRight?: (value: boolean) => void;
  endlessLeft?: boolean;
  endlessRight?: boolean;
  withSwiperMode?: boolean;

}

export const useSlider = ({
  fullWidthScroll = false,
  slidesPerScroll = 1,
  autoScrollTimer = 3000,
  duration = 350,
  withAutoScroll = false,
  onSwipe = () => { },
  onEndPositionLeft = () => { },
  onEndPositionRight = () => { },
  endlessLeft = false,
  endlessRight = false,
  withSwiperMode,
}: SliderProps) => {
  const autoScrollIntervalId = useRef<number>(null);
  const currentScrollLeft = useRef<number>(null);
  const viewport = useRef<HTMLDivElement>(null);

  const [isEndPositionRight, setEndPositionRight] = useState(false);
  const [isEndPositionLeft, setEndPositionLeft] = useState(true);
  const [currentPosition, setCurrentPosition] = useState(0);

  const childrenSlidesCount = viewport.current && viewport.current.children.length;

  useEffect(() => {
    onEndPositionRight(isEndPositionRight);
  }, [isEndPositionRight, onEndPositionRight]);

  useEffect(() => {
    onEndPositionLeft(isEndPositionLeft);
  }, [isEndPositionLeft, onEndPositionLeft]);

  useEffect(() => {
    if (withSwiperMode && viewport.current) {
      viewport.current.style.overflow = 'hidden';
    }

    if (withAutoScroll) {
      launchAutoScroll();
    }

    if (viewport.current) {
      setBoundaryPosition(viewport.current, {
        onBegin: setEndPositionLeft,
        onEnd: setEndPositionRight,
        isBegin: isEndPositionLeft,
        isEnd: isEndPositionRight,
      });
      setSlidesDataStatus(viewport.current);
    }

    return () => {
      window.clearInterval(autoScrollIntervalId.current);
    };
    /** @todo simplify method */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (viewport.current) {
      setBoundaryPosition(viewport.current, {
        onBegin: setEndPositionLeft,
        onEnd: setEndPositionRight,
        isBegin: isEndPositionLeft,
        isEnd: isEndPositionRight,
      });
      setSlidesDataStatus(viewport.current);
    }
    /** @todo use dependencies */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenSlidesCount]);

  useEffect(() => {
    onSwipe(currentPosition);
    if (viewport.current) {
      currentScrollLeft.current = viewport.current.scrollLeft;
    }
  }, [currentPosition, onSwipe]);

  const handleScrollViewport = useCallback(() => {
    if (viewport.current) {
      setBoundaryPosition(viewport.current, {
        onBegin: setEndPositionLeft,
        onEnd: setEndPositionRight,
        isBegin: isEndPositionLeft,
        isEnd: isEndPositionRight,
      });
      setSlidesDataStatus(viewport.current);
    }
  }, [isEndPositionLeft, isEndPositionRight]);

  const handleTouchStart = useCallback((event: React.TouchEvent<HTMLDivElement>): void => {
    window.clearInterval(autoScrollIntervalId.current);

    if (!withSwiperMode) {
      return;
    }
    touchXData.touchStart = event.changedTouches[0].clientX;
    touchXData.timestampStart = Date.now();

    currentScrollLeft.current = viewport.current.scrollLeft;
  }, [withSwiperMode]);

  const handleTouchEnd = useCallback((event: React.TouchEvent<HTMLDivElement>): void => {
    if (!withSwiperMode) {
      return;
    }

    touchXData.touchEnd = event.changedTouches[0].clientX;
    touchXData.timestampEnd = Date.now();
    const { swipeDirection, eventType } = getSwipeParams(touchXData);

    let position: number | null = null;
    if (endlessLeft && isEndPositionLeft) {
      position = viewport.current.children.length - 1;
    }

    if (endlessRight && isEndPositionRight) {
      position = 0;
    }

    if (eventType === 'swipe') {
      scrollSlider(viewport.current, {
        scrollDirection: swipeDirection,
        callback: setCurrentPosition,
        duration: duration,
        position: position,
      });
    }

    if (eventType === 'move') {
      const nextPosition = getIsVisible(viewport.current, currentPosition, 0.3)
        ? currentPosition
        : null;

      scrollSlider(viewport.current, {
        scrollDirection: swipeDirection,
        callback: setCurrentPosition,
        duration: duration,
        position: nextPosition,
      });
    }
  }, [currentPosition, duration, endlessLeft, endlessRight, isEndPositionLeft, isEndPositionRight, withSwiperMode]);

  const handleTouchMove = useCallback((event: React.TouchEvent<HTMLDivElement>): void => {
    if (!withSwiperMode) {
      return;
    }

    touchXData.touchCurrent = event.changedTouches[0].clientX;

    const { touchStart, touchCurrent } = touchXData;
    const spacing = touchStart - touchCurrent;

    viewport.current.scrollLeft = currentScrollLeft.current + spacing;
  }, [withSwiperMode]);

  function handleMouseOver(): void {
    window.clearInterval(autoScrollIntervalId.current);
  }

  const launchAutoScroll = useCallback(() => {
    if (!withAutoScroll || (viewport.current && viewport.current.children.length) <= 1) {
      return;
    }

    autoScrollIntervalId.current = window.setInterval(() => {
      if (isEndPositionRight)
        scrollSlider(viewport.current, {
          callback: setCurrentPosition,
          duration: duration,
          position: 0,
        });
      else
        scrollSlider(viewport.current, {
          scrollDirection: 'right',
          duration: duration,
          callback: setCurrentPosition,
        });
    }, autoScrollTimer);
  }, [autoScrollTimer, duration, isEndPositionRight, withAutoScroll]);

  const handleMouseOut = useCallback((): void => {
    launchAutoScroll();
  }, [launchAutoScroll]);

  function handleNext(): void {
    let newPosition = currentPosition + slidesPerScroll;

    // Если новый индекс больше или равен общему числу слайдов
    if (newPosition >= childrenSlidesCount) {
      if (endlessRight) {
        newPosition = 0; // Если включен режим бесконечной прокрутки, возвращаемся в начало
      } else {
        newPosition = childrenSlidesCount - 1; // Иначе останавливаемся на последнем слайде
      }
    }

    scrollSlider(viewport.current, {
      scrollDirection: 'right',
      callback: setCurrentPosition,
      slidesPerScroll,
      fullWidthScroll,
      duration: duration,
      position: newPosition,
    });
  }

  function handlePrev(): void {
    let newPosition = currentPosition - slidesPerScroll;

    // Если новый индекс меньше нуля
    if (newPosition < 0) {
      if (endlessLeft) {
        newPosition = childrenSlidesCount - 1; // Если включен режим бесконечной прокрутки, переходим на последний слайд
      } else {
        newPosition = 0; // Иначе останавливаемся на первом слайде
      }
    }

    scrollSlider(viewport.current, {
      scrollDirection: 'left',
      callback: setCurrentPosition,
      slidesPerScroll,
      fullWidthScroll,
      duration: duration,
      position: newPosition,
    });
  }

  function goToSlide(position: number): void {
    scrollSlider(viewport.current, {
      callback: setCurrentPosition,
      duration: duration,
      position,
    });
  }

  return {
    sliderRef: viewport,
    controls: {
      onNextSlide: handleNext,
      onPrevSlide: handlePrev,
      onGoToSlide: goToSlide,
      sliderState: {
        currentPosition,
        isEndPositionLeft,
        isEndPositionRight,
      },
    },
    sliderControls: {
      handleTouchStart,
      handleTouchEnd,
      handleTouchMove,
      handleScrollViewport,
      handleMouseOver,
      handleMouseOut,
    },
  };
};
